<template>
  <div class="ebook">
    <horizontal-ebook v-if="direction === 'horizontal'" :is-wx-env="isWxEnv" />
    <vertical-ebook v-if="direction === 'vertical'" :is-wx-env="isWxEnv" />
  </div>
</template>

<script>
import axios from 'axios'

import HorizontalEbook from '../../components/green/ebook/HorizontalEbook.vue'
import VerticalEbook from '../../components/green/ebook/VerticalEbook.vue'

export default {
  name: 'GreenEbook',
  
  components: { HorizontalEbook, VerticalEbook },

  data() {
    return {
      // 电子书内容展示的方向
      direction: 'horizontal'
    }
  },

  computed: {
    //判断是不是微信浏览器
    isWxEnv() {
      let ua = navigator.userAgent.toLowerCase()
      return ua.match(/MicroMessenger/i) !== null
    }
  },
  
  created() {
    const { direction = 'horizontal' } = this.$route.query
    this.direction = direction

    const { isWxEnv = false } = this
    if (isWxEnv) {
      const that = this
      this.getWxConfigData().then(() => {
        const imgUrl = 'https://directimg.miaocang.cc/r3p5giq9t6oj5xftgnmg.png'
        that.wxShareHandler(
          {
            title: '横栏花木采购指南',
            imgUrl,
          },
          {
            title: '横栏花木采购指南',
            desc: '横栏花木采购指南',
            imgUrl,
          },
          window.location.href
        )
      })
    }

    window.addEventListener('resize', () => {
      location.reload()
    })
  },

  methods: {
    /* 获取微信 js-sdk 配置数据 */
    getWxConfigData() {
      return axios.post(
        'https://api.miaocang.cc/api/weixin/wxshare.htm',
        { url: window.location.href }
      ).then((res) => {
        const {
          appId,
          timestamp,
          noncestr: nonceStr,
          signature
        } = res.data.data
        wx.config({
          debug: false,
          appId, // 必填，公众号的唯一标识
          timestamp, // 必填，生成签名的时间戳
          nonceStr, // 必填，生成签名的随机串
          signature, // 必填，签名，见附录1
          jsApiList: ['onMenuShareTimeline', 'onMenuShareAppMessage'],
          openTagList: ['wx-open-launch-weapp'] // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
        })
      })
    },

    /* 微信分享卡片参数处理 */
    wxShareHandler(timeline, message, url) {
      // 获取“分享到朋友圈”按钮点击状态及自定义分享内容接口
      wx.onMenuShareTimeline({
        title: timeline.title || '红遍中山的小树苗－－', // 分享标题
        link: url,
        imgUrl: timeline.imgUrl || 'https://img.miaocang.cc/h5-img/logo.png', // 分享图标
        success: () => {
          //打日志
          console.log('朋友圈分享成功')
        },
        cancel: () => {
          console.log('朋友圈分享成功失败')
        },
      })
      // 获取“分享给朋友”按钮点击状态及自定义分享内容接口
      wx.onMenuShareAppMessage({
        title: message.title || '红遍中山的小树苗－－', // 分享标题
        desc: message.desc || '风靡全国的', // 分享描述
        link: url,
        imgUrl: message.imgUrl || 'https://img.miaocang.cc/h5-img/logo.png', // 分享图标
        type: 'link', // 分享类型,music、video或link，不填默认为link
        success: () => {
          //打日志
          console.log('朋友分享成功')
        },
        cancel: () => {
          console.log('朋友分享成功失败')
        },
      })
    },
  }
}
</script>
