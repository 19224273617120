<template>
  <div
    v-if="slideUpMaskVisible"
    class="slide-up-mask"
    @click="slideUpMaskVisible = false"
    @touchstart="slideUpMaskVisible = false"
  >
    <div class="wrapper">
      <img :src="img.finger" class="finger" />
      <img :src="img.up" class="up" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SlideUpMask',

  data() {
    return {
      // 上滑引导层的可见性
      slideUpMaskVisible: false,

      // 图标
      img: {
        finger: require('@/assets/images/green/ebook/icon_finger@2x.png'),
        up: require('@/assets/images/green/ebook/img_up@2x.png'),
      }
    }
  },

  created() {
    this.slideUpMaskHandler()
  },

  methods: {
    // 上滑引导层动画处理
    slideUpMaskHandler() {
      const that = this
      setTimeout(() => {
        that.slideUpMaskVisible = true
      }, 500)
      setTimeout(() => {
        if (that.slideUpMaskVisible) {
          that.slideUpMaskVisible = false
        }
      }, 2500)
    }
  }
}
</script>

<style lang="less" scoped>
.slide-up-mask {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background: rgb(133,133,133);
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 100%);
  opacity: 0.75;
  z-index: 200;
  text-align: center;
  .wrapper {
    position: absolute;
    bottom: 10vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .finger {
    width: 64px;
    height: 72px;
    transform: translate(40px, 70px);
    animation: slide-up 1s infinite;
  }
  @keyframes slide-up {
    0% {
    transform: translate(40px, 70px);
    }
    100% {
      transform: translate(40px, 0);
    }
  }
  .up {
    width: 42px;
    height: 60px;
  }
}
</style>
