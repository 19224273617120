var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"wrapper",staticClass:"wrapper",on:{"scroll":_vm.onWrapperScroll}},[_vm._l((_vm.list),function(item,index){return _c('div',{key:item.img,class:`page page-${index + 1}`,style:({
      width: `calc(100% * ${_vm.zoomRatio})`,
      height: `calc(${item.height}px * ${_vm.zoomRatio})`
    })},[(index !== 1)?_c('van-image',{attrs:{"src":item.img,"lazy-load":""}}):(index === 1)?_c('div',{class:`page page${index + 1} catalogue-container`,style:({
        width: `calc(${_vm.screenWidth}px * ${_vm.zoomRatio})`,
        height: `calc(100vh * ${_vm.zoomRatio})`
        })},[_c('div',{staticClass:"title-wrap"},[_c('div',{staticClass:"title"},[_vm._v("目录")]),_c('div',{staticClass:"sub-title"},[_vm._v("Contents")])]),_c('div',{staticClass:"list-wrap"},[_c('div',{staticClass:"item",on:{"click":function($event){return _vm.turnToPage(3)}}},[_c('div',{staticClass:"primary"},[_c('span',[_vm._v("一、横栏苗木采购及运营介绍")]),_c('div',{staticClass:"catalogue-btn"})]),_c('div',{staticClass:"secondary"},[_vm._v("(p3-p5)")])]),_c('div',{staticClass:"item",on:{"click":function($event){return _vm.turnToPage(6)}}},[_c('div',{staticClass:"primary"},[_c('span',[_vm._v("二、横栏苗木信息")]),_c('div',{staticClass:"catalogue-btn"})]),_c('div',{staticClass:"secondary"},[_vm._v("(p6-p23)")])]),_c('div',{staticClass:"item",on:{"click":function($event){return _vm.turnToPage(24)}}},[_c('div',{staticClass:"primary"},[_c('span',[_vm._v("三、农村四小园")]),_c('div',{staticClass:"catalogue-btn"})]),_c('div',{staticClass:"secondary"},[_vm._v("(p24-p57)")])]),(_vm.isWxEnv)?_c('div',{staticClass:"item"},[_c('div',{staticClass:"primary"},[_c('span',[_vm._v("四、苗木采购商场")]),_c('div',{staticClass:"catalogue-btn"})]),_c('miniprogram-launcher',{attrs:{"path":"/pages/green/green"}})],1):_vm._e()]),_c('div',{staticClass:"bottom-bg",style:({ width: `calc(${_vm.screenWidth}px * ${_vm.zoomRatio})`, height: `calc(${_vm.screenWidth * _vm.zoomRatio}px * 0.6)` })})]):_vm._e()],1)}),_c('div',{staticClass:"control-wrap"},[_c('div',{staticClass:"control"},[_c('div',{staticClass:"pager"},[_vm._v(_vm._s(`${_vm.currentPage}/${_vm.imageList.length}`))]),_c('div',{staticClass:"slider"},[_c('van-slider',{attrs:{"min":1,"max":_vm.imageList.length,"button-size":"16","bar-height":"6","active-color":"#fff","inactive-color":"#959595"},on:{"change":(value) => _vm.turnToPage(value)},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1),_c('div',{class:{
          'zoom-btn-img': true,
          // 'invisible': turnPage.currentPage === 2
        },on:{"click":function($event){return _vm.onZoomIn(0.5)}}}),_c('div',{class:{
          'reset-btn-img': true,
          // 'invisible': turnPage.currentPage === 2
        },on:{"click":_vm.onResetZoom}})])]),_c('transition',{attrs:{"name":"fade"}},[_c('slide-up-mask')],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }