<template>
  <div ref="wrapper" class="wrapper" @scroll="onWrapperScroll">
    <div
      v-for="(item, index) in list"
      :key="item.img"
      :style="{
        width: `calc(100% * ${zoomRatio})`,
        height: `calc(${item.height}px * ${zoomRatio})`
      }"
      :class="`page page-${index + 1}`"
    >
      <!-- 每一页都是一整张图片 -->
      <van-image v-if="index !== 1" :src="item.img" lazy-load />
      <!-- 第二页为目录 -->
      <div
        v-else-if="index === 1"
        :style="{
          width: `calc(${screenWidth}px * ${zoomRatio})`,
          height: `calc(100vh * ${zoomRatio})`
          }"
        :class="`page page${index + 1} catalogue-container`"
      >
        <div class="title-wrap">
          <div class="title">目录</div>
          <div class="sub-title">Contents</div>
        </div>
        <div class="list-wrap">
          <div class="item" @click="turnToPage(3)">
            <div class="primary">
              <span>一、横栏苗木采购及运营介绍</span>
              <div class="catalogue-btn" />
            </div>
            <div class="secondary">(p3-p5)</div>
          </div>
          <div class="item" @click="turnToPage(6)">
            <div class="primary">
              <span>二、横栏苗木信息</span>
              <div class="catalogue-btn" />
            </div>
            <div class="secondary">(p6-p23)</div>
          </div>
          <div class="item" @click="turnToPage(24)">
            <div class="primary">
              <span>三、农村四小园</span>
              <div class="catalogue-btn" />
            </div>
            <div class="secondary">(p24-p57)</div>
          </div>
          <div v-if="isWxEnv" class="item">
            <div class="primary">
              <span>四、苗木采购商场</span>
              <div class="catalogue-btn" />
            </div>
            <miniprogram-launcher path="/pages/green/green" />
          </div>
        </div>
        <div :style="{ width: `calc(${screenWidth}px * ${zoomRatio})`, height: `calc(${screenWidth * zoomRatio}px * 0.6)` }" class="bottom-bg" />
      </div>
    </div>
    <!-- 底部控制栏 -->
    <div class="control-wrap">
      <div class="control">
        <!-- 页码 -->
        <div class="pager">{{ `${currentPage}/${imageList.length}` }}</div>
        <!-- 页码滑块 -->
        <div class="slider">
          <van-slider 
            v-model="currentPage"
            :min="1"
            :max="imageList.length"
            button-size="16"
            bar-height="6"
            active-color="#fff"
            inactive-color="#959595"
            @change="(value) => turnToPage(value)"
          />
        </div>
        <!-- 放大按钮 -->
        <div
          :class="{
            'zoom-btn-img': true,
            // 'invisible': turnPage.currentPage === 2
          }"
          @click="onZoomIn(0.5)"
        />
        <!-- 重置放大比例按钮 -->
        <div
          :class="{
            'reset-btn-img': true,
            // 'invisible': turnPage.currentPage === 2
          }"
          @click="onResetZoom"
        />
      </div>
    </div>
    <!-- 上滑引导层 -->
    <transition name="fade">
      <slide-up-mask />
    </transition>
  </div>
</template>

<script>
import SlideUpMask from './SlideUpMask.vue'
import MiniprogramLauncher from '../../MiniprogramLauncher.vue'

export default {
  name: 'VerticalEbook',

  components: { SlideUpMask, MiniprogramLauncher },

  props: {
    // 当前是否属于微信浏览器环境
    isWxEnv: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      // 当前页码
      currentPage: 1,
      // 当前页面放大的比例
      zoomRatio: 1,
      // 当前窗口的宽度
      screenWidth: 375,

      // 当前的垂直滚动距离
      scrollTop: 0,
      
      // 图片列表
      imageList: [
        {
          img: require('@/assets/images/green/ebook/img_01@2x.png'),
          aspectRatio: 812 / 375,
        },
        {
          img: '',
          aspectRatio: 667 / 375,
        },
        {
          img: require('@/assets/images/green/ebook/img_03@2x.png'),
          aspectRatio: 343 / 150,
        },
        {
          img: require('@/assets/images/green/ebook/img_04@2x.png'),
          aspectRatio: 776 / 375,
        },
        {
          img: require('@/assets/images/green/ebook/img_05@2x.png'),
          aspectRatio: 724 / 375,
        },
        {
          img: require('@/assets/images/green/ebook/img_06@2x.png'),
          aspectRatio: 724 / 375,
        },
        {
          img: require('@/assets/images/green/ebook/img_07@2x.png'),
          aspectRatio: 724 / 375,
        },
        {
          img: require('@/assets/images/green/ebook/img_08@2x.png'),
          aspectRatio: 724 / 375,
        },
        {
          img: require('@/assets/images/green/ebook/img_009@2x.png'),
          aspectRatio: 682 / 375,
        },
        {
          img: require('@/assets/images/green/ebook/img_010@2x.png'),
          aspectRatio: 43 / 25,
        },
        {
          img: require('@/assets/images/green/ebook/img_011@2x.png'),
          aspectRatio: 43 / 25,
        },
        {
          img: require('@/assets/images/green/ebook/img_012@2x.png'),
          aspectRatio: 43 / 25,
        },
        {
          img: require('@/assets/images/green/ebook/img_013@2x.png'),
          aspectRatio: 1291 / 750,
        },
        {
          img: require('@/assets/images/green/ebook/img_014@2x.png'),
          aspectRatio: 43 / 25,
        },
        {
          img: require('@/assets/images/green/ebook/img_015@2x.png'),
          aspectRatio: 203 / 150,
        },
        {
          img: require('@/assets/images/green/ebook/img_016@2x.png'),
          aspectRatio: 682 / 375,
        },
        {
          img: require('@/assets/images/green/ebook/img_017@2x.png'),
          aspectRatio: 1291 / 750,
        },
        {
          img: require('@/assets/images/green/ebook/img_018@2x.png'),
          aspectRatio: 1291 / 750,
        },
        {
          img: require('@/assets/images/green/ebook/img_019@2x.png'),
          aspectRatio: 229 / 125,
        },
        {
          img: require('@/assets/images/green/ebook/img_020@2x.png'),
          aspectRatio: 682 / 375,
        },
        {
          img: require('@/assets/images/green/ebook/img_021@2x.png'),
          aspectRatio: 43 / 25,
        },
        {
          img: require('@/assets/images/green/ebook/img_022@2x.png'),
          aspectRatio: 43 / 25,
        },
        {
          img: require('@/assets/images/green/ebook/img_023@2x.png'),
          aspectRatio: 11 / 6,
        },
        {
          img: require('@/assets/images/green/ebook/img_20@2x.png'),
          aspectRatio: 1624 / 751,
        },
        {
          img: require('@/assets/images/green/ebook/img_21@2x.png'),
          aspectRatio: 724 / 375,
        },
        {
          img: require('@/assets/images/green/ebook/img_22@2x.png'),
          aspectRatio: 724 / 375,
        },
        {
          img: require('@/assets/images/green/ebook/img_23@2x.png'),
          aspectRatio: 724 / 375,
        },
        {
          img: require('@/assets/images/green/ebook/img_024@2x.png'),
          aspectRatio: 724 / 375,
        },
        {
          img: require('@/assets/images/green/ebook/img_025@2x.png'),
          aspectRatio: 1081 / 750,
        },
        {
          img: require('@/assets/images/green/ebook/img_026@2x.png'),
          aspectRatio: 229 / 150,
        },
        {
          img: require('@/assets/images/green/ebook/img_027@2x.png'),
          aspectRatio: 227 / 150,
        },
        {
          img: require('@/assets/images/green/ebook/img_028@2x.png'),
          aspectRatio: 1093 / 750,
        },
        {
          img: require('@/assets/images/green/ebook/img_029@2x.png'),
          aspectRatio: 127 / 75,
        },
        {
          img: require('@/assets/images/green/ebook/img_030@2x.png'),
          aspectRatio: 1187 / 751,
        },
        {
          img: require('@/assets/images/green/ebook/img_031@2x.png'),
          aspectRatio: 1271 / 750,
        },
        {
          img: require('@/assets/images/green/ebook/img_032@2x.png'),
          aspectRatio: 347 / 250,
        },
        {
          img: require('@/assets/images/green/ebook/img_033@2x.png'),
          aspectRatio: 1313 / 750,
        },
        {
          img: require('@/assets/images/green/ebook/img_034@2x.png'),
          aspectRatio: 347 / 250,
        },
        {
          img: require('@/assets/images/green/ebook/img_035@2x.png'),
          aspectRatio: 592 / 375,
        },
        {
          img: require('@/assets/images/green/ebook/img_036@2x.png'),
          aspectRatio: 229 / 150,
        },
        {
          img: require('@/assets/images/green/ebook/img_037@2x.png'),
          aspectRatio: 77 / 50,
        },
        {
          img: require('@/assets/images/green/ebook/img_038@2x.png'),
          aspectRatio: 1093 / 750,
        },
        {
          img: require('@/assets/images/green/ebook/img_039@2x.png'),
          aspectRatio: 632 / 375,
        },
        {
          img: require('@/assets/images/green/ebook/img_040@2x.png'),
          aspectRatio: 989 / 750,
        },
        {
          img: require('@/assets/images/green/ebook/img_041@2x.png'),
          aspectRatio: 1183 / 750,
        },
        {
          img: require('@/assets/images/green/ebook/img_042@2x.png'),
          aspectRatio: 347 / 250,
        },
        {
          img: require('@/assets/images/green/ebook/img_043@2x.png'),
          aspectRatio: 1123 / 750,
        },
        {
          img: require('@/assets/images/green/ebook/img_044@2x.png'),
          aspectRatio: 989 / 750,
        },
        {
          img: require('@/assets/images/green/ebook/img_045@2x.png'),
          aspectRatio: 1187 / 750,
        },
        {
          img: require('@/assets/images/green/ebook/img_046@2x.png'),
          aspectRatio: 989 / 750,
        },
        {
          img: require('@/assets/images/green/ebook/img_047@2x.png'),
          aspectRatio: 1187 / 750,
        },
        {
          img: require('@/assets/images/green/ebook/img_048@2x.png'),
          aspectRatio: 1031 / 750,
        },
        {
          img: require('@/assets/images/green/ebook/img_049@2x.png'),
          aspectRatio: 1069 / 750,
        },
        {
          img: require('@/assets/images/green/ebook/img_050@2x.png'),
          aspectRatio: 1031 / 750,
        },
        {
          img: require('@/assets/images/green/ebook/img_051@2x.png'),
          aspectRatio: 614 / 375,
        },
        {
          img: require('@/assets/images/green/ebook/img_052@2x.png'),
          aspectRatio: 1031 / 750,
        },
        {
          img: require('@/assets/images/green/ebook/img_053@2x.png'),
          aspectRatio: 198 / 125,
        }
      ]
    }
  },

  computed: {
    // 处理后的图片列表
    list() {
      const { imageList, screenWidth } = this
      let totalHeight = 0
      return imageList.map(item => {
        const { aspectRatio = 1 } = item
        const prevTotalHeight = totalHeight
        totalHeight += Math.floor(screenWidth * aspectRatio)
        return {
          ...item,
          top: prevTotalHeight,
          height: totalHeight - prevTotalHeight
        }
      })
    },

    
    // 总页数
    totalPage() {
      return this.list.length
    }
  },

  watch: {
    // 容器垂直的滚动距离监听处理
    scrollTop(scrollTop) {
      const { list = [], zoomRatio = 1 } = this
      const topList = list.map(({ top }) => top * zoomRatio)
      const len = topList.length
      let currentPage = 1
      if (scrollTop > topList[len - 2] && Math.abs(topList[len - 1] - scrollTop) < Math.abs(topList[len - 2] - scrollTop)) {
        // 若当前滚动位置为最后两页之间
        currentPage = len
      } else {
        // 正常情况
        const index = topList.findIndex((top, i) => scrollTop >= top && scrollTop < topList[i + 1])
        currentPage = index + 1
      }
      this.currentPage = currentPage
    }
  },

  created() {
    const { width } = screen
    if (width > 640) {
      this.screenWidth = 640
    } else if (width < 320) {
      this.screenWidth = 320
    } else {
      this.screenWidth = width
    }
  },

  methods: {
    /* 跳转到指定页码的页面 */
    turnToPage(page) {
      const { zoomRatio } = this
      this.currentPage = page
      this.$refs['wrapper'].scrollTop = Math.ceil(this.list[page - 1].top * zoomRatio)
    },

    /* 放大按钮的点击事件处理 */
    onZoomIn(step) {
      let { zoomRatio } = this
      zoomRatio += step
      this.zoomRatio = zoomRatio
      this.scrollToCurrentPage()
    },
    
    /* 重置放大比例 */
    onResetZoom() {
      if (this.zoomRatio !== 1) {
        this.zoomRatio = 1
        this.scrollToCurrentPage()
      }
    },

    /* 容器滚动事件监听 */
    onWrapperScroll(e) {
      const { scrollTop } = e.target
      this.scrollTop = Math.ceil(scrollTop)
    },

    /* 当放大比例改变时, 滚动到当前页 */
    scrollToCurrentPage() {
      const that = this
      setTimeout(() => {
        that.$nextTick(() => {
          const { currentPage, list, zoomRatio } = that
          const { top } = list[currentPage - 1]
          const scrollTop = Math.ceil(top * zoomRatio)
          that.scrollTop = scrollTop
          that.$refs['wrapper'].scrollTop = scrollTop
        })
      }, 200)
    }
  }
}
</script>

<style lang="less" scoped>
.wrapper {
  position: relative;
  min-width: 320px;
  max-width: 640px;
  height: 100vh;
  margin: 0 auto;
  overflow: scroll;
  scrollbar-width: none;
}

.page {
  width: 100%;
  transition-duration: 0.125s;
  will-change: auto;
  // min-height: 100vh;
}

.control-wrap {
  position: fixed;
  bottom: 0;
  width: 100%;
  min-width: 320px;
  max-width: 640px;
  text-align: center;
  height: 8vh;
  line-height: 6vh;
  // background-color: rgba(128, 128, 128, 0.4);
  z-index: 100;
  .control {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 0 auto;
    padding: 0 2rem;
    user-select: none;
    .pager {
      text-shadow: 0 0 2px #000;
      font-size: 0.8rem;
      color: #fff;
    }
    .slider {
      width: 66%;
    }
    .zoom-btn-img {
      width: 1.7rem;
      height: 1.7rem;
      background-image: url('../../../assets/images/green/ebook/img_big@2x.png');
      background-size: 100% 100%;
      cursor: pointer;
      user-select: none;
    }
    .reset-btn-img {
      width: 1.3rem;
      height: 1.3rem;
      background-image: url('../../../assets/images/green/ebook/icon_normal@2x.png');
      background-size: 100% 100%;
      cursor: pointer;
      user-select: none;
    }
  }
}

.catalogue-container {
  position: relative;
  width: 100%;
  height: 100vh;
  user-select: none;
  .title-wrap {
    display: flex;
    flex-direction: column;
    gap: 0;
    padding: 1.4rem;
    .title {
      font-size: 4.4rem;
      font-weight: 500;
      color: #4EB0B4;
    }
    .sub-title {
      font-size: 3.75rem;
      color: #e0e1e1;
    }
  }
  .list-wrap {
    display: flex;
    flex-direction: column;
    padding: 0 1.4rem 1.4rem 1.4rem;
    gap: 1rem;
    .item {
      position: relative;
      cursor: pointer;
      .primary {
        display: flex;
        align-items: center;
        gap: 1rem;
        font-size: 1.1rem;
        color: #00B3B5;
        .catalogue-btn {
          width: 4rem;
          height: 1.6rem;
          user-select: none;
          background-image: url('../../../assets/images/green/ebook/button_next@2x.png');
          background-size: 100% 100%;
        }
      }
      .secondary {
        font-size: 12px;
        color: #00B7B6;
      }
    }
  }
  .bottom-bg {
    position: absolute;
    bottom: 0;
    // width: 100%;
    height: 30vh;
    user-select: none;
    background-image: url('../../../assets/images/green/ebook/img_02_bottom@2x.png');
    background-size: 100% 100%;
  }
}


.invisible {
  visibility: hidden;
}

::v-deep .van-slider__bar {
  border: 1px solid #979797b3;
}

::v-deep .van-image {
  width: 100%;
  height: 100%;
  & img {
    width: 100%;
    height: 100%;
  }
}
</style>
