<template>
  <div class="wrapper">
    <div class="container" ref="container">
      <div class="view" :style="{ height: turnPage.height + 'px' }">
        <div
          class="hard"
          :style="{
            width: turnPage.width / 2 + 'px',
            height: turnPage.height + 'px',
          }"
          style="background: #4f4f4f"
        />
        <!-- turn.js 电子书容器 -->
        <div id="book" class="book" v-if="turnPage.show">
          <div
            class="page"
            :class="{ odd: index % 2 == 0, even: index % 2 == 1 }"
            v-for="(item, index) in turnPage.imageList"
            :key="index"
          >
            <!-- 每一页都是一整张图片 -->
            <div v-if="index !== 1" class="image">
              <img
                v-if="Math.abs(turnPage.currentPage - (index + 1)) <= 1"
                v-show="turnPage.currentPage === index + 1"
                :src="item"
                :style="{
                  width: `${zoomRatio * 100}%`,
                  minHeight: `${zoomRatio * 100}%`
                }"
              />
            </div>
            <!-- 第二页为目录 -->
            <div v-else-if="index === 1" class="page catalogue-container">
              <div class="title-wrap">
                <div class="title">目录</div>
                <div class="sub-title">Contents</div>
              </div>
              <div class="list-wrap">
                <div class="item" @click="turnToPage(3)">
                  <div class="primary">
                    <span>一、横栏苗木采购及运营介绍</span>
                    <div class="catalogue-btn" />
                  </div>
                  <div class="secondary">(p3-p5)</div>
                </div>
                <div class="item" @click="turnToPage(6)">
                  <div class="primary">
                    <span>二、横栏苗木信息</span>
                    <div class="catalogue-btn" />
                  </div>
                  <div class="secondary">(p6-p19)</div>
                </div>
                <div class="item" @click="turnToPage(20)">
                  <div class="primary">
                    <span>三、农村四小园</span>
                    <div class="catalogue-btn" />
                  </div>
                  <div class="secondary">(p20-p53)</div>
                </div>
                <div v-if="isWxEnv" class="item">
                  <div class="primary">
                    <span>四、苗木采购商场</span>
                    <div class="catalogue-btn" />
                  </div>
                  <miniprogram-launcher path="/pages/green/green" />
                </div>
              </div>
              <div class="bottom-bg" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 上一页箭头按钮 -->
    <div v-show="turnPage.currentPage != 1" class="prev-btn" @click="onTurnPre" />
    <!-- 下一页箭头按钮 -->
    <div v-show="turnPage.currentPage < turnPage.totalPage" class="next-btn" @click="onTurnNext" />
    <!-- 底部控制栏 -->
    <div class="control-wrap">
      <div class="control">
        <!-- 页码 -->
        <div class="pager">{{ `${turnPage.currentPage}/${turnPage.totalPage}` }}</div>
        <!-- 页码滑块 -->
        <div class="slider">
          <van-slider 
            v-model="turnPage.currentPage"
            :min="1"
            :max="turnPage.totalPage"
            button-size="16"
            bar-height="6"
            active-color="#fff"
            inactive-color="#959595"
            @change="(value) => turnToPage(value)"
          />
        </div>
        <!-- 放大按钮 -->
        <div
          :class="{
            'zoom-btn-img': true,
            'invisible': turnPage.currentPage === 2
          }"
          @click="onZoomIn(0.5)"
        />
        <!-- 重置放大比例按钮 -->
        <div
          :class="{
            'reset-btn-img': true,
            'invisible': turnPage.currentPage === 2
          }"
          @click="onResetZoom"
        />
      </div>
    </div>
  </div>
</template>

<script>
import turn from '@/lib/turn.min.js'

import MiniprogramLauncher from '@/components/MiniprogramLauncher.vue'

export default {
  name: 'HorizontalEbook',

  props: {
    isWxEnv: {
      type: Boolean,
      default: false
    }
  },

  components: { MiniprogramLauncher },

  data() {
    return {
      turnPage: {
        width: 0,
        height: 0,
        imageList: [],
        currentPage: 1,
        totalPage: 1,
        show: true,
      },
      zoomRatio: 1,
    }
  },
  
  created() {
    for (let i = 1; i <=  53; i++) {
      try {
        const img = require(`@/assets/images/green/ebook/img_${i < 10 ? `0${i}` : i}@2x.png`)
        this.turnPage.imageList.push(img)
      } catch (error) {
        this.turnPage.imageList.push('') 
      }
    }
  },

  mounted() {
    // 获取div容器的宽高
    const width = this.$refs.container.offsetWidth
    const height = this.$refs.container.offsetHeight
    // 设置div容器的宽高
    console.log(width, height)
    this.turnPage = {
      ...this.turnPage,
      width,
      height,
    }
    this.turnPage.totalPage = this.turnPage.imageList.length
    this.onTurn()
    $(window).bind('keydown', function (e) {
      if (e.target && e.target.tagName.toLowerCase() != 'input')
        if (e.keyCode == 37) $('#book').turn('previous')
        else if (e.keyCode == 39) $('#book').turn('next')
    })
  },

  methods: {
    /* turn.js 配置初始化 */
    onTurn() {
      const that = this
      this.$nextTick(() => {
        $('#book').turn({
          //启用硬件加速,移动端有效
          acceleration: true,
          //显示：single=单页，double=双页，默认双页
          display: 'single',
          // 翻页撒开鼠标，页面的延迟
          duration: 600,
          // 默认显示第几页
          page: 1,
          // 折叠处的光泽渐变，主要体现翻页的立体感、真实感
          gradients: true,
          // 中心翻取决于有多少页面可见 true or false
          autoCenter: true,
          // 设置可翻页的页角(都试过了，乱写 4个角都能出发卷起)， bl,br   tl,tr   bl,tr
          turnCorners: 'bl,br,tl,tr',
          //页面高度
          height: this.turnPage.height,
          //翻书范围宽度，总宽度
          width: this.turnPage.width,
          when: {
            //监听事件
            turning: function (e, page, view) {
              console.log('翻页前触发');
              console.log(e, page, view);
              // 翻页前触发
              console.log(page);
            },
            turned: function (e, page) {
              console.log('翻页后触发');
              console.log(e, page);
              // 翻页后触发
              console.log(page);
              const length = that.turnPage.imageList.length
              that.turnPage.currentPage = page < length ? page : length
            },
          },
        })
      })
    },

    /* 上一页箭头按钮的点击事件处理 (翻到上一页) */
    onTurnPre() {
      let { currentPage } = this.turnPage
      this.$set(this.turnPage, 'currentPage', --currentPage)
      $('#book').turn('previous')
    },
    
    /* 下一页箭头按钮的点击事件处理 (翻到下一页) */
    onTurnNext() {
      let { currentPage } = this.turnPage
      this.$set(this.turnPage, 'currentPage', ++currentPage)
      $('#book').turn('next')
    },
    
    /* 跳转到指定页码的页面 */
    turnToPage(page) {
      this.$set(this.turnPage, 'currentPage', page)
      $('#book').turn('page', page)
    },

    /* 放大按钮的点击事件处理 */
    onZoomIn(step) {
      this.zoomRatio += step
    },
    
    /* 重置放大比例 */
    onResetZoom() {
      if (this.zoomRatio !== 1) {
        this.zoomRatio = 1
      }
    }
  },
};
</script>

<style lang="less" scoped>
#book {
  .turn-page {
    background-color: #4f4f4f;
  }

  .odd {
    /* background-image: -webkit-linear-gradient(left, #fff 95%, #ddd 100%);
    background-image: -moz-linear-gradient(left, #fff 95%, #ddd 100%);
    background-image: -o-linear-gradient(left, #fff 95%, #ddd 100%);
    background-image: -ms-linear-gradient(left, #fff 95%, #ddd 100%); */
    background-image: linear-gradient(270deg, #fff 95%, #ddd 100%);
  }
  
  .even {
    background-image: linear-gradient(90deg, #fff 95%, #ddd 100%);
  
    /* background-image: -webkit-linear-gradient(right, #fff 95%, #ddd 100%);
    background-image: -moz-linear-gradient(right, #fff 95%, #ddd 100%);
    background-image: -o-linear-gradient(right, #fff 95%, #ddd 100%);
    background-image: -ms-linear-gradient(right, #fff 95%, #ddd 100%); */
  }
}

.wrapper {
  position: relative;
  width: 100%;
  min-width: 320px;
  max-width: 640px;
  height: 100vh;
  margin: 0 auto;
  background: #4f4f4f;
}

.container {
  width: 100%;
  height: 100%;
  background: #fff;
  position: relative;
  margin: 0 auto;
}

.view {
  position: relative;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
}

.hard {
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.page-load {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page {
  background: #fff;
}

.page .image {
  width: 100%;
  height: 100vh;
  overflow: scroll;
  scrollbar-width: none;
}

.page .image img {
  object-fit: cover;
  object-position: 0 0;
  user-select: none;
  transition-duration: 0.125s;
  will-change: auto;
}

.prev-btn {
  position: absolute;
  top: calc(50% - 3rem / 2);
  left: 0.4rem;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  user-select: none;
  z-index: 100;
  background-image: url('../../../assets/images/green/ebook/icon_left@2x.png');
  background-size: 100% 100%;
}

.next-btn {
  position: absolute;
  top: calc(50% - 3rem / 2);
  right: 0.4rem;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  user-select: none;
  z-index: 100;
  background-image: url('../../../assets/images/green/ebook/icon_right@2x.png');
  background-size: 100% 100%;
}

.control-wrap {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 8vh;
  line-height: 6vh;
  // background-color: rgba(128, 128, 128, 0.4);
  z-index: 100;
  .control {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 0 auto;
    padding: 0 2rem;
    user-select: none;
    .pager {
      text-shadow: 0 0 2px #000;
      font-size: 0.8rem;
      color: #fff;
    }
    .slider {
      width: 66%;
    }
    .zoom-btn-img {
      width: 1.7rem;
      height: 1.7rem;
      background-image: url('../../../assets/images/green/ebook/img_big@2x.png');
      background-size: 100% 100%;
      cursor: pointer;
      user-select: none;
    }
    .reset-btn-img {
      width: 1.3rem;
      height: 1.3rem;
      background-image: url('../../../assets/images/green/ebook/icon_normal@2x.png');
      background-size: 100% 100%;
      cursor: pointer;
      user-select: none;
    }
  }
}

.catalogue-container {
  position: relative;
  width: 100%;
  height: 100%;
  user-select: none;
  .title-wrap {
    display: flex;
    flex-direction: column;
    gap: 0;
    padding: 1.4rem;
    .title {
      font-size: 4.4rem;
      font-weight: 500;
      color: #4EB0B4;
    }
    .sub-title {
      font-size: 3.75rem;
      color: #e0e1e1;
    }
  }
  .list-wrap {
    display: flex;
    flex-direction: column;
    padding: 0 1.4rem 1.4rem 1.4rem;
    gap: 1rem;
    .item {
      position: relative;
      cursor: pointer;
      .primary {
        display: flex;
        align-items: center;
        gap: 1rem;
        font-size: 1.1rem;
        color: #00B3B5;
        .catalogue-btn {
          width: 4rem;
          height: 1.6rem;
          user-select: none;
          background-image: url('../../../assets/images/green/ebook/button_next@2x.png');
          background-size: 100% 100%;
        }
      }
      .secondary {
        font-size: 12px;
        color: #00B7B6;
      }
    }
  }
  .bottom-bg {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 30vh;
    user-select: none;
    background-image: url('../../../assets/images/green/ebook/img_02_bottom@2x.png');
    background-size: 100% 100%;
  }
}


.invisible {
  visibility: hidden;
}

::v-deep .van-slider__bar {
  border: 1px solid #979797b3;
}
</style>
