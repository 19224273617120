var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('div',{ref:"container",staticClass:"container"},[_c('div',{staticClass:"view",style:({ height: _vm.turnPage.height + 'px' })},[_c('div',{staticClass:"hard",staticStyle:{"background":"#4f4f4f"},style:({
          width: _vm.turnPage.width / 2 + 'px',
          height: _vm.turnPage.height + 'px',
        })}),(_vm.turnPage.show)?_c('div',{staticClass:"book",attrs:{"id":"book"}},_vm._l((_vm.turnPage.imageList),function(item,index){return _c('div',{key:index,staticClass:"page",class:{ odd: index % 2 == 0, even: index % 2 == 1 }},[(index !== 1)?_c('div',{staticClass:"image"},[(Math.abs(_vm.turnPage.currentPage - (index + 1)) <= 1)?_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.turnPage.currentPage === index + 1),expression:"turnPage.currentPage === index + 1"}],style:({
                width: `${_vm.zoomRatio * 100}%`,
                minHeight: `${_vm.zoomRatio * 100}%`
              }),attrs:{"src":item}}):_vm._e()]):(index === 1)?_c('div',{staticClass:"page catalogue-container"},[_vm._m(0,true),_c('div',{staticClass:"list-wrap"},[_c('div',{staticClass:"item",on:{"click":function($event){return _vm.turnToPage(3)}}},[_vm._m(1,true),_c('div',{staticClass:"secondary"},[_vm._v("(p3-p5)")])]),_c('div',{staticClass:"item",on:{"click":function($event){return _vm.turnToPage(6)}}},[_vm._m(2,true),_c('div',{staticClass:"secondary"},[_vm._v("(p6-p19)")])]),_c('div',{staticClass:"item",on:{"click":function($event){return _vm.turnToPage(20)}}},[_vm._m(3,true),_c('div',{staticClass:"secondary"},[_vm._v("(p20-p53)")])]),(_vm.isWxEnv)?_c('div',{staticClass:"item"},[_vm._m(4,true),_c('miniprogram-launcher',{attrs:{"path":"/pages/green/green"}})],1):_vm._e()]),_c('div',{staticClass:"bottom-bg"})]):_vm._e()])}),0):_vm._e()])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.turnPage.currentPage != 1),expression:"turnPage.currentPage != 1"}],staticClass:"prev-btn",on:{"click":_vm.onTurnPre}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.turnPage.currentPage < _vm.turnPage.totalPage),expression:"turnPage.currentPage < turnPage.totalPage"}],staticClass:"next-btn",on:{"click":_vm.onTurnNext}}),_c('div',{staticClass:"control-wrap"},[_c('div',{staticClass:"control"},[_c('div',{staticClass:"pager"},[_vm._v(_vm._s(`${_vm.turnPage.currentPage}/${_vm.turnPage.totalPage}`))]),_c('div',{staticClass:"slider"},[_c('van-slider',{attrs:{"min":1,"max":_vm.turnPage.totalPage,"button-size":"16","bar-height":"6","active-color":"#fff","inactive-color":"#959595"},on:{"change":(value) => _vm.turnToPage(value)},model:{value:(_vm.turnPage.currentPage),callback:function ($$v) {_vm.$set(_vm.turnPage, "currentPage", $$v)},expression:"turnPage.currentPage"}})],1),_c('div',{class:{
          'zoom-btn-img': true,
          'invisible': _vm.turnPage.currentPage === 2
        },on:{"click":function($event){return _vm.onZoomIn(0.5)}}}),_c('div',{class:{
          'reset-btn-img': true,
          'invisible': _vm.turnPage.currentPage === 2
        },on:{"click":_vm.onResetZoom}})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-wrap"},[_c('div',{staticClass:"title"},[_vm._v("目录")]),_c('div',{staticClass:"sub-title"},[_vm._v("Contents")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"primary"},[_c('span',[_vm._v("一、横栏苗木采购及运营介绍")]),_c('div',{staticClass:"catalogue-btn"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"primary"},[_c('span',[_vm._v("二、横栏苗木信息")]),_c('div',{staticClass:"catalogue-btn"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"primary"},[_c('span',[_vm._v("三、农村四小园")]),_c('div',{staticClass:"catalogue-btn"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"primary"},[_c('span',[_vm._v("四、苗木采购商场")]),_c('div',{staticClass:"catalogue-btn"})])
}]

export { render, staticRenderFns }