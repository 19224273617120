var render = function render(){var _vm=this,_c=_vm._self._c;return _c('wx-open-launch-weapp',{style:({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    'z-index': 10000,
  }),attrs:{"id":"wxOpenLaunchWeapp","appid":_vm.appId,"username":_vm.username,"extra-data":_vm.extraData,"path":_vm.path},on:{"error":_vm.onError}},[_c('script',{attrs:{"type":"text/wxtag-template"}},[_vm._v(" <div style=\"position: absolute; top: 0; left: 0; width: 100%; height: 100%; opacity: 0;\"><br><br></div> ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }