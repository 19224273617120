<template>
  <wx-open-launch-weapp
    id="wxOpenLaunchWeapp"
    :appid="appId"
    :username="username"
    :extra-data="extraData"
    :path="path"
    :style="{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      'z-index': 10000,
    }"
    @error="onError"
  >
    <script type="text/wxtag-template">
      <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; opacity: 0;"><br><br></div>
    </script>
  </wx-open-launch-weapp>
</template>

<script>
export default {
  name: "MiniprogramLauncher",

  props: {
    // 微信环境时, 微信小程序的 appid
    appId: {
      type: String,
      default: "wx2e509022d28eec52",
    },
    // 所需跳转的小程序原始id，即小程序对应的以gh_开头的id
    username: {
      type: String,
      default: 'gh_d46545673bc2',
    },
    // 所需跳转的小程序内页面路径及参数
    path: {
      type: String,
      default: ''
    },
    // 微信环境时, 打开APP需要传值的 extraData (格式与 app scheme 相同)
    extraData: {
      type: String,
      default: "",
    },
    // 是否处于微信环境调试模式
    debug: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    /* 用户点击跳转按钮后出现错误的事件监听处理 */
    onError(e) {
      console.log('onError', e)
      const { debug = true } = this
      debug && console.log(e.detail)
      // 微信跳转 APP 失败, 跳转到下载页
      // this.toDownloadPage();
    },

    /* 跳转到下载页 */
    // toDownloadPage() {
    //   const { extraData: appScheme } = this;
    //   this.$router.push({
    //     name: "download",
    //     ...(appScheme && {
    //       query: {
    //         appScheme,
    //       },
    //     }),
    //   });
    // },
  },
};
</script>
